import IdentityProvider from 'client-app-omnivise-web/utils/identity-providers/idp-interface';
import Keycloak from 'keycloak-js';
import config from 'client-app-omnivise-web/config/environment';

class UnauthenticatedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnauthenticatedError';
  }
}
export default class KeycloakIdentityProvider extends IdentityProvider {
  kc;
  isAuthenticated = false;

  constructor(message) {
    super(message);
    const { url, realm, clientId } = config.authConfig.keycloakConfig;
    this.kc = new Keycloak({ url, realm, clientId });
  }

  get ownTrustZone() {
    return config.authConfig.ownAuthTrustZone;
  }

  async _initKC(mode) {
    // _initKC is a private method that is called by `login` and `pickupSessionState`
    // depending on which of the invocations are done, the mode is different
    // login -> login-required
    // pickupSessionState -> check-sso
    // once the iniialization is done, the `this.kc.didInitialize` flag is set to true and it is safe to
    // call apis on the keycloak instance, (i,e, `login()`, `logout()`, and access the `token` property)

    if (this.kc.didInitialize) {
      return;
    }
    try {
      return await this.kc.init({ onLoad: mode });
    } catch (error) {
      throw new UnauthenticatedError(
        `KeyCloak Login failed with ${error?.message}`
      );
    }
  }

  async login() {
    this.kc.didInitialize
      ? await this.kc.login()
      : await this._initKC('login-required');
  }

  async logout() {
    const logoutOptions = {
      post_logout_redirect_uri:
        config.authConfig.keycloakConfig.postLogoutRedirectUri,
      client_id: config.authConfig.keycloakConfig.clientId,
    };

    return this.kc.didInitialize && (await this.kc.logout(logoutOptions));
  }

  async pickupSessionState() {
    if (!this.kc.didInitialize) {
      this.isAuthenticated = await this._initKC('check-sso');
    }

    if (!this.isAuthenticated) {
      return null;
    }

    return {
      accessToken: this.kc.token,
      provider: 'keycloak',
    };
  }

  async update() {
    if (!this.kc.didInitialize) {
      this.isAuthenticated = await this._initKC('check-sso');
    }

    if (!this.isAuthenticated) {
      return null;
    }

    await this.kc.updateToken();
    return this.kc.token;
  }

  async ensureAccessTokenFor() {
    // eslint-disable-next-line
    console.log('Keycloak ensureAccessTokenFor');
  }
}
