import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import config from 'client-app-omnivise-web/config/environment';

export default class Application extends Route {
  @service session;
  @service userCentric;
  @service metrics;

  @service('route-change') routeChangeService;

  async beforeModel() {
    try {
      // eslint-disable-next-line
      console.log('application route');

      // Ensure that a valid session is picked up. This also ensures that a
      // valid access token for own trust zone is present.
      await this.session.pickupSessionState();

      if (this.session.isAuthenticated && this.session.redirectUri) {
        await this.session.redirectToDestinationAfterAuthentication();
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('beforeModel application error', error);
    }
  }
  afterModel() {
    if (config.featureFlags['google-analytics']) {
      //ember-metrics configuration
      this.metrics.activateAdapters([
        {
          name: 'GoogleAnalyticsFour',
          environments: ['all'],
          config: {
            id: config.OWC_GA4_ID,
            options: {
              anonymize_ip: true,
              debug_mode: config.environment !== 'production',
              send_page_view: false,
            },
          },
        },
      ]);
    }
    this.routeChangeService.listenForRouteChanges();

    //Call the usercentric
    this.userCentric.verifyUCEvents();
  }
}
