import Service, { inject as service } from '@ember/service';

export default class OmniviseMetrics extends Service {
  @service router;
  @service metrics;
  @service session;
  @service features;

  trackPageView() {
    if (!this.features.isEnabled('google-analytics')) {
      return;
    }
    if (this.session.isAuthenticated) {
      this.#populateMetricsContextWithUserInfo();
    }

    const page = this.router.currentURL;
    const title = this.router.currentRouteName || 'unknown';

    this.metrics.trackPage({ page, title });
  }

  trackPageViewByAdobeAnalytics() {
    if (!this.features.isEnabled('adobe-analytics')) {
      return;
    }
    const page = this.router.currentURL;
    const pathValue = page.split('?');

    window.ste_statistic = window.ste_statistic || [];
    const obj = {
      action: 'page.ready',
      data: {
        page: {
          path: pathValue[0],
          country: 'WW',
          language: 'en',
        },
      },
    };

    if (this.session.isAuthenticated)
      obj['data']['user'] = this.#populateUserInfo();

    window.ste_statistic.push(obj);
    /* eslint-disable no-console */
    console.log('Adobe Analytics Object: ', window.ste_statistic);
  }

  #populateMetricsContextWithUserInfo() {
    const { uid, sub } = this.session.ownClaims;
    this.metrics.context['user_properties'] = {
      gaUserId: uid,
      isInternal: this.#isInternalUser(sub) + '',
    };
  }

  #isInternalUser(sub) {
    // Not sure how reliable this approach is.
    // Should be replaced with a more robust business rule that comes from the PO.
    return sub.endsWith('@siemens.com') || sub.endsWith('@siemens-energy.com');
  }

  #populateUserInfo() {
    const { uid, sub } = this.session.ownClaims;
    const isInternalUser = this.#isInternalUser(sub) + '';
    return {
      customerId: uid,
      isInternal: isInternalUser,
    };
  }
}
