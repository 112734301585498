import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
import normalizeLocaleToApi from 'client-app-omnivise-web/utils/get-normalized-locale';

export default class extends Model {
  @service intl;

  @attr('string') 'description_de-DE';
  @attr('string') 'description_en-US';
  @attr('string') icon;
  @attr('string') name;
  @attr('boolean') readOnly;
  @attr('date') createdAt;
  @attr('date') updatedAt;

  @hasMany('module-badge') moduleBadges;
  @belongsTo('module-information-detail') informationDetail;
  @hasMany('module-category') moduleCategories;
  @hasMany('view', { polymorphic: true }) views;

  get description() {
    const { locale } = this.intl;

    return this[`description_${normalizeLocaleToApi(locale.firstObject)}`];
  }

  get hasInformationDetail() {
    // To avoid lazy loading
    return !!this.belongsTo('informationDetail').id();
  }
}
