import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 * TODO: Replace this with the known landing page's internal view id once
 * it is available on the back-end.
 */
export const LANDING_PAGE_INTERNAL_VIEW_ID = 'fixture-landing-page';

export default class IndexRoute extends Route {
  @service router;

  async beforeModel() {
    this.router.transitionTo('auth.view', LANDING_PAGE_INTERNAL_VIEW_ID);
  }
}
