import Service, { inject as service } from '@ember/service';
import config from 'client-app-omnivise-web/config/environment';

export default class RouteChangeService extends Service {
  @service router;
  @service('omnivise-metrics') omniviseMetricsService;
  @service('url-state') urlStateService;
  @service userCentric;
  @service features;

  listenForRouteChanges() {
    this.router.on('routeDidChange', (transition) => {
      if (config.featureFlags['omnivise-tickets']) {
        const viewId = transition.to.params.view_id;
        const enableTooltipButton = this.isPDSView(viewId);
        enableTooltipButton
          ? this.features.enable('tooltip-button')
          : this.features.disable('tooltip-button');
        if (this.isMyHealthView(viewId)) {
          this.features.disable('omnivise-tickets');
          this.features.enable('omnivise-tickets-myhealth');
        } else {
          this.features.enable('omnivise-tickets');
          this.features.disable('omnivise-tickets-myhealth');
        }
      }
      this.updateUrlStatesPerView(transition);
      if (this.userCentric.isUCPerformanceEnabled) {
        this.trackPageView();
        this.trackPageViewByAdobeAnalytics();
      }
    });
  }

  isPDSView(viewId) {
    return (
      viewId &&
      (viewId.toLowerCase().includes('pds') ||
        viewId.toLowerCase().includes('gen-advisor'))
    );
  }

  isMyHealthView(viewId) {
    return (
      viewId &&
      (viewId.toLowerCase().includes('my-health') ||
        viewId.toLowerCase().includes('myhealth'))
    );
  }

  updateUrlStatesPerView(transition) {
    this.urlStateService.updateUrlStatesPerView(transition);
  }

  trackPageView() {
    this.omniviseMetricsService.trackPageView();
  }

  trackPageViewByAdobeAnalytics() {
    this.omniviseMetricsService.trackPageViewByAdobeAnalytics();
  }
}
