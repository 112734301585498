import { helper } from '@ember/component/helper';
import { assert } from '@ember/debug';

export function addEventListenerToElement([element, functions]) {
  assert(
    'First argument must be an HTML element',
    element instanceof HTMLElement
  );
  assert(
    'Second argument must be hash of functions',
    typeof functions === 'object'
  );
  assert(
    'Second argument must have at least one function',
    Object.keys(functions).length !== 0
  );
  assert(
    'Second argument must have functions only',
    Object.values(functions).every((fnValue) => typeof fnValue === 'function')
  );
  Object.keys(functions).forEach((fn) => {
    element.addEventListener(fn, functions[fn]);
  });

  // return element to make usage in template more ergonomic
  return element;
}

export default helper(addEventListenerToElement);
