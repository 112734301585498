import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import logErrorUtil from 'client-app-omnivise-web/utils/log-error';

export default class LoginRoute extends Route {
  @service session;
  @service router;

  focused = true;

  async beforeModel(transition) {
    if (this.session.isAuthenticated) {
      // A valid Okta session exists. Redirect user either to given redirect URL or
      // to default page for authenticated users.
      this.isComingFromAuthenticatedRoute(transition)
        ? transition.abort()
        : this.session.redirectToDestinationAfterAuthentication();
    } else {
      // Try to get an OKTA session by redirecting to OKTA.
      // If the OKTA server detects that the user has a valid SiemensID session,
      // it will establish an OKTA session and redirect back to our application
      try {
        return await this.session.pickupSessionState();
      } catch (error) {
        // In case SiemensID or AzureAD sessions do not not exist proceed to login page
        if (error.error !== 'login_required') {
          logErrorUtil.logError(error);
        }
      }
    }
  }

  isComingFromAuthenticatedRoute(transition) {
    return transition.from?.name.startsWith('auth');
  }

  // User should be transitioned to /apps if he authenticates in another browser window.
  // Observing localStorage for changes cause that one indicates that access token was
  // changed inanother instance. Storage event is not fired for current instance.
  onLocalStorageChanged({ key, newValue }) {
    // ignore changes that aren't affecting access token or removing access token
    if (key !== this.session.ownTrustZone || !newValue || this.focused) {
      return;
    }
    this.router.transitionTo('auth');
  }

  onWindowFocus() {
    if (!(this.isDestroyed || this.isDestroying)) {
      this.focused = true;
    }
  }

  onWindowBlur() {
    if (!(this.isDestroyed || this.isDestroying)) {
      this.focused = false;
    }
  }

  activate() {
    window.addEventListener('focus', this.onWindowFocus.bind(this));
    window.addEventListener('blur', this.onWindowBlur.bind(this));
    window.addEventListener('storage', this.onLocalStorageChanged.bind(this));
  }

  deactivate() {
    window.removeEventListener('focus', this.onWindowFocus.bind(this));
    window.removeEventListener('blur', this.onWindowBlur.bind(this));
    window.removeEventListener(
      'storage',
      this.onLocalStorageChanged.bind(this)
    );
  }
}
