import IdentityProvider from 'client-app-omnivise-web/utils/identity-providers/idp-interface';
import { OktaAuth } from '@okta/okta-auth-js';
import { assert } from '@ember/debug';
import config from 'client-app-omnivise-web/config/environment';

class UnauthenticatedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnauthenticatedError';
  }
}

class UnknownAuthError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnknownAuthError';
  }
}

export default class OktaIdentityProvider extends IdentityProvider {
  get oktaConfig() {
    const { clientId, authorizationServerId, oktaDomain } = config.authConfig;

    assert('clientId config is missing', !!clientId);
    assert('authorizationServerId config is missing', !!authorizationServerId);
    assert('oktaDomain config is missing', !!oktaDomain);

    return {
      clientId,
      issuer: `${oktaDomain}/oauth2/${authorizationServerId}`,
      pkce: false,
      redirectUri: `${window.location.origin}/callback`,
    };
  }

  login() {
    window.location.href = config.authConfig.oktaUrl;
  }

  async logout() {
    await this.authClient.revokeAccessToken();
    await this.authClient.closeSession();
  }

  async update(authScope) {
    return await this.fetchAccessToken([authScope]);
  }

  async ensureAccessTokenFor(authScope) {
    return await this.fetchAccessToken([authScope]);
  }

  async pickupSessionState() {
    // eslint-disable-next-line
    console.log('pickupOktaSessionState');
    let accessToken;
    try {
      accessToken = await this.fetchAccessToken([this.ownTrustZone]);
      // eslint-disable-next-line
      console.log('pickupOktaSessionState success');
    } catch (e) {
      // eslint-disable-next-line
      console.log('pickupOktaSessionState error', e);
      return false;
    }

    return {
      accessToken,
      provider: 'okta',
    };
  }

  /*
   * Fetches an access token from Okta.
   *
   * Consumer may pass an Okta session token. In that case it will not only
   * fetch the access token but also establish an Okta cookie session in
   * parallel.
   */
  async fetchAccessToken(scopes, sessionToken) {
    const options = {
      responseType: 'token',
      scopes,
    };

    if (sessionToken) {
      options.sessionToken = sessionToken;
    }

    let response;
    try {
      response = await this.authClient.token.getWithoutPrompt(options);
    } catch (error) {
      // TODO: Implement granular error indicating the reasons:
      //
      // - If Okta session does not exist, is expired or is invalid (`LOCKED_OUT`): Reject with `UnauthenticatedError`.
      // - If valid Okta session exists but trust zone is not configured
      //   correctly: Log to Sentry and reject with `InvalidOktaConfiguration`.
      // - If network is not available: Reject with `NetworkError`.
      // - ...
      // - In all other cases: Reject with `UnknownAuthError`.
      throw new UnauthenticatedError(
        `Fetching access token for with scopes ${scopes.join(
          ' '
        )} failed with ${error.message}`
      );
    }

    const accessToken = response?.tokens?.accessToken?.value;

    if (!accessToken) {
      throw new UnknownAuthError(
        `Fetching access token for with scopes ${scopes.join(
          ' '
        )} failed. AuthClient.token.getWithoutPrompt() resolved but response ` +
          'did not contain an access token.'
      );
    }

    return accessToken;
  }

  async fetchOwnAccessToken({ sessionToken }) {
    return await this.fetchAccessToken([this.ownTrustZone], sessionToken);
  }

  get ownTrustZone() {
    return config.authConfig.ownAuthTrustZone;
  }

  constructor(message) {
    super(message);
    this.authClient = new OktaAuth(this.oktaConfig);
  }
}
