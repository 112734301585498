import IdentityProvider from 'client-app-omnivise-web/utils/identity-providers/idp-interface';
import msal from '@azure/msal-browser';
import config from 'client-app-omnivise-web/config/environment';

class UnauthenticatedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnauthenticatedError';
  }
}

export default class AzureIdentityProvider extends IdentityProvider {
  azureInstance = new msal.PublicClientApplication(this.azureConfig);

  get ownTrustZone() {
    return config.authConfig.ownAuthTrustZone;
  }

  get graphScope() {
    return config.authConfig.graphScope;
  }

  get azureConfig() {
    return config.authConfig.azureConfig;
  }

  get environment() {
    return config.environment;
  }

  get azureScopes() {
    return [`${this.azureConfig.auth.clientId}/.default`];
  }

  async ensureAccessTokenFor(authScope) {
    const isGraphScope = authScope === this.graphScope;
    const scopes = isGraphScope ? [this.graphScope] : this.azureScopes;
    const account = this.azureInstance.getAllAccounts()[0];
    return await this.acquireTokenSilentAzure(scopes, account);
  }

  async login() {
    // eslint-disable-next-line
    console.log('loginRedirectAzure');
    try {
      //This wrapper required to resolve the interaction_in_progress issue
      //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
      const response = await this.azureInstance.handleRedirectPromise();

      if (response) {
        return response.accessToken;
      }
      const accounts = this.azureInstance.getAllAccounts();
      if (accounts.length === 0) {
        await this.azureInstance.loginRedirect({
          scopes: this.azureScopes,
        });
      }
    } catch (error) {
      throw new UnauthenticatedError(
        `Fetching access token for with scopes failed with ${error.message}`
      );
    }
  }

  async acquireTokenSilentAzure(scopes, account) {
    // eslint-disable-next-line
    console.log('acquireTokenSilentAzure');
    try {
      this.resp = await this.azureInstance.acquireTokenSilent({
        forceRefresh: true,
        scopes,
        account,
      });
      return this.resp.accessToken;
    } catch (error) {
      // eslint-disable-next-line
      console.log(
        `Fetching access token for with scopes failed with ${error.message}`
      );
      return null;
    }
  }

  async update(authScope) {
    let accessToken;
    const isGraphScope = authScope === this.graphScope;
    const scopes = isGraphScope ? [this.graphScope] : this.azureScopes;
    const currentAccounts = this.azureInstance.getAllAccounts();
    if (currentAccounts.length === 0) {
      //sso is not working with the current local tests setup
      if (config.environment === 'test') {
        return null;
      }

      accessToken = await this.sso(scopes);
      // eslint-disable-next-line
      console.log('ssoSilentAzure success');
    } else {
      const account = currentAccounts[0];
      accessToken = await this.acquireTokenSilentAzure(scopes, account);
      // eslint-disable-next-line
      console.log('acquireTokenSilentAzure success');
    }

    return accessToken;
  }

  async logout() {
    const account = this.azureInstance.getAllAccounts()[0];
    this.resp = await this.azureInstance.logoutRedirect({
      mainWindowRedirectUri: this.azureConfig.auth.mainWindowRedirectUri,
      scopes: this.azureScopes,
      account,
    });
  }

  async sso(scopes) {
    // eslint-disable-next-line
    console.log('ssoSilentAzure');
    try {
      this.resp = await this.azureInstance.ssoSilent({
        scopes: scopes || this.azureScopes,
      });
      return this.resp.accessToken;
    } catch (error) {
      // eslint-disable-next-line
      console.log(`SSO failed with ${error.message}`);
    }
  }

  async pickupSessionState() {
    // eslint-disable-next-line
    console.log('pickupAzureSessionState');
    try {
      const response = await this.azureInstance.handleRedirectPromise();

      let accessToken;
      if (response) {
        accessToken = response.accessToken;
        // eslint-disable-next-line
        console.log('handleRedirectPromise success');
      } else {
        accessToken = await this.update();
      }

      return accessToken
        ? {
            accessToken,
            provider: 'azure',
          }
        : null;
    } catch (error) {
      throw new UnauthenticatedError(
        `AzureAD handle redirect promise fail ${error.message}`
      );
    }
  }
}
