import Service, { inject as service } from '@ember/service';

export default class UserCentricService extends Service {
  @service metrics;
  @service cookies;
  @service session;

  verifyUCEvents() {
    window?.UC_UI?.getServicesBaseInfo().filter((data) => {
      if (data.id == this.session.config.ucPerformanceAAId) {
        if (data.consent.status == false) this.removedGA();
      }
    });
    window.addEventListener('UC_UI_CMP_EVENT', (event) => {
      if (event.detail.type == 'SAVE') {
        this.confirmMyChoice();
      }
    });
  }

  get isUCPerformanceEnabled() {
    return window?.UC_UI?.getServicesBaseInfo().find(
      (data) => data.id === this.session.config.ucPerformanceAAId
    ).consent.status;
  }

  async confirmMyChoice() {
    const accpeted_services_consents =
      await window.UC_UI.getServicesBaseInfo().filter(
        (data) => data.consent.status === false
      );

    //check the ids of accpeted_services_consents and save only those cookies
    accpeted_services_consents.forEach((element) => {
      if (element.id == this.session.config.ucPerformanceAAId) {
        this.removedGA();
      }
    });
  }
  removedGA() {
    this.cookies.clear('_ga', { domain: '.siemens-energy.com', path: '/' });
    this.cookies.clear('_gat', { domain: '.siemens-energy.com', path: '/' });
    this.cookies.clear('_gid', { domain: '.siemens-energy.com', path: '/' });
    // this will destroy GA metric but not clear the cookies..
    this.metrics.willDestroy();
  }
  openSecondLayerConsent() {
    if (window.UC_UI && window.UC_UI.isInitialized()) {
      window.UC_UI.showSecondLayer();
    } else {
      window.addEventListener('UC_UI_INITIALIZED', function () {
        window.UC_UI.showSecondLayer();
      });
    }
  }
  onRestartEmbeddings() {
    window?.UC_UI?.restartEmbeddings();
  }
}
