import IdentityProvider from 'client-app-omnivise-web/utils/identity-providers/idp-interface';
import IdentityProviderFactory from 'client-app-omnivise-web/utils/identity-providers/idp-factory';

export default class IdentityProviderFacade extends IdentityProvider {
  constructor(requiredIdps) {
    super(requiredIdps);

    this.requiredIdps = requiredIdps;
    this.idps = {};
    this.requiredIdps.forEach(
      (idpName) =>
        (this.idps[idpName] = IdentityProviderFactory.createIdp(idpName))
    );
  }

  async login(idpName) {
    return await this.idps[idpName].login();
  }

  logout() {
    this.requiredIdps.reverse().forEach((idpName) => {
      this.idps[idpName].logout();
    });
  }

  async update(provider, authScope) {
    return await this.idps[provider].update(authScope);
  }

  async ensureAccessTokenFor(authScope, provider) {
    return await this.idps[provider].ensureAccessTokenFor(authScope);
  }

  async pickupSessionState(provider) {
    let accessTokenInfo = null;
    accessTokenInfo =
      provider && (await this.idps[provider]?.pickupSessionState());
    for (const idpName of this.requiredIdps) {
      if (!accessTokenInfo) {
        accessTokenInfo = await this.idps[idpName].pickupSessionState();
      }
    }
    return accessTokenInfo;
  }
}
