import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! load notifications when user authenticates _after_ component has been rendered }}\n{{did-update this.handleAuthenticationChange this.session.isAuthenticated}}\n\n{{yield\n  (hash\n    toggler=(component\n      \"notifications-manager/toggler\"\n      unreadNotificationsCount=this.unreadNotifications.length\n      shouldShowError=this.setupWebSocketAndLoadNotifications.last.isError\n    )\n    list=(component\n      \"notifications-manager/list\"\n      pdcNotifications=this.sortedPdcNotifications\n      isNotificationsLoading=(not this.hasNotificationsBeenLoadedOnce)\n    )\n  )\n}}", {"contents":"{{! load notifications when user authenticates _after_ component has been rendered }}\n{{did-update this.handleAuthenticationChange this.session.isAuthenticated}}\n\n{{yield\n  (hash\n    toggler=(component\n      \"notifications-manager/toggler\"\n      unreadNotificationsCount=this.unreadNotifications.length\n      shouldShowError=this.setupWebSocketAndLoadNotifications.last.isError\n    )\n    list=(component\n      \"notifications-manager/list\"\n      pdcNotifications=this.sortedPdcNotifications\n      isNotificationsLoading=(not this.hasNotificationsBeenLoadedOnce)\n    )\n  )\n}}","moduleName":"client-app-omnivise-web/components/notifications-manager.hbs","parseOptions":{"srcName":"client-app-omnivise-web/components/notifications-manager.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';
import { next } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import { action } from '@ember/object';
import config from 'client-app-omnivise-web/config/environment';
import { registerDestructor, unregisterDestructor } from '@ember/destroyable';
import logErrorUtil from 'client-app-omnivise-web/utils/log-error';

export const RETRY_DELAY = 30000;

export default class NotificationsManager extends Component {
  @service
  store;

  @service session;
  @service features;

  @service notification;

  @tracked hasNotificationsBeenLoadedOnce = false;
  @tracked pdcNotifications = [];

  @tracked webSocket;

  wasAuthenticatedBefore;

  get allowToConnect() {
    return (
      this.session.isAuthenticated && this.features.isEnabled('notifications')
    );
  }

  constructor(owner, args) {
    super(owner, args);

    if (this.features.isEnabled('notifications')) {
      this.initNotifications();

      this.notification.on('refreshNotifications', () => {
        this.unloadNotifications();
        this.initNotifications();
      });
    }
  }

  initNotifications() {
    this.wasAuthenticatedBefore = this.session.isAuthenticated;
    if (this.allowToConnect) {
      this.setupWebSocketAndLoadNotifications.perform();
    }
  }

  unloadNotifications() {
    this.store
      .peekAll('pdcNotification')
      .forEach((notification) => notification.unloadRecord());
  }

  get isWebSocketAlive() {
    return (
      this.webSocket &&
      ![WebSocket.CLOSING, WebSocket.CLOSED].includes(this.webSocket.readyState)
    );
  }

  @task({ keepLatest: true })
  *setupWebSocketAndLoadNotifications() {
    // WebSocket connection should be established _before_ fetching existing
    // notifications via HTTP API to avoid missing notifications created or
    // updated between fetching over HTTP and establishing WebSocket connection
    try {
      yield this.initialiseWebSocket.perform();
    } catch (error) {
      // notifications should be loaded over HTTP regardless if WebSocket
      // connection was established successfully or not

      // log the error for debugging
      logErrorUtil.logError(error);

      // fetch notifications over HTTP
      yield this.loadNotifications.perform();

      // task should be considered as having an error anyways
      throw new Error(
        'Successfully loaded notifications over HTTP but initializing WebSocket failed',
        { cause: error }
      );
    }

    // We have code duplication instead of using finally because if task is canceled
    // finally will still be executed. loadNotifications must not be called when
    // canceling the task
    yield this.loadNotifications.perform();
  }

  @task
  *loadNotifications() {
    if (this.session.currentTime / 1000 < this.session.ownClaims.exp) {
      try {
        const pdcNotificationsManyArray = yield this.store.query(
          'pdcNotification',
          {
            type: ['failed_sensor_comment', 'report', 'report_comment'].join(
              ','
            ),
            recent_read: 30,
            page: -1,
          }
        );

        this.pdcNotifications = pdcNotificationsManyArray.toArray();

        this.hasNotificationsBeenLoadedOnce = true;
      } catch (e) {
        logErrorUtil.logError(e);
        if (this.session.isAuthenticated) {
          this.retryAfterDelay.perform();
        }
        throw e;
      }
    }
  }

  @task({ drop: true })
  *retryAfterDelay() {
    yield timeout(RETRY_DELAY);
    if (this.allowToConnect) {
      this.setupWebSocketAndLoadNotifications.perform();
    }
  }

  @action
  handleAuthenticationChange() {
    // Don't ask me why we need to delay this to the next runloop. I guess
    // because we execute this function by a helper. All I know is that _not_
    // delaying it to the next runloop invalides `isAuthenticated`, which
    // leads to an endless loop.
    next(() => {
      const { isAuthenticated } = this.session;
      const { wasAuthenticatedBefore } = this;
      const hasAuthenticationChanged =
        isAuthenticated !== wasAuthenticatedBefore;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (!hasAuthenticationChanged) {
        // Skip if authentication has not changed
        return;
      }

      if (this.allowToConnect) {
        this.setupWebSocketAndLoadNotifications.perform();
      } else {
        this.cleanupAfterLogOut();
      }

      this.wasAuthenticatedBefore = isAuthenticated;
    });
  }

  cleanupAfterLogOut() {
    // close active Websocket connection
    if (this.isWebSocketAlive) {
      this.webSocket.close();
    }

    // make sure not retries or running connection attempts after log out
    this.retryAfterDelay.cancelAll();
    this.setupWebSocketAndLoadNotifications.cancelAll();

    // only set tracked variable if component is not destroyed
    if (!this.isDestroyed && !this.isDestroying) {
      this.hasNotificationsBeenLoadedOnce = false;
    }
  }

  get unreadNotifications() {
    return this.sortedPdcNotifications.filter(
      (notification) => !notification.isRead
    );
  }

  get sortedPdcNotifications() {
    return [...this.pdcNotifications].sort(
      (a, b) =>
        new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime()
    );
  }

  @task
  *initialiseWebSocket() {
    if (this.isWebSocketAlive) {
      return;
    }
    const { ownAccessToken: accessToken } = this.session;
    const { webSocketHost } = config.api;

    yield new Promise((resolve, reject) => {
      this.webSocket = new WebSocket(
        `${webSocketHost}/pdc-notifications-ws?authorization=${accessToken}`
      );
      const webSocketDestructor = registerDestructor(this, () => {
        this.cleanupAfterLogOut();
      });
      this.webSocket.addEventListener('close', () => {
        if (!this.isDestroyed && !this.isDestroying) {
          unregisterDestructor(this, webSocketDestructor);
        }
      });
      this.webSocket.addEventListener('open', function () {
        resolve();
      });
      this.webSocket.addEventListener('error', function (error) {
        reject(
          new Error(
            'Establishing PDC Notifications WebSocket failed or established connection dropped unexpected',
            { cause: error }
          )
        );
      });
      this.webSocket.addEventListener(
        'close',
        this.onNotficationConnectionClose
      );
      this.webSocket.addEventListener(
        'message',
        this.onNotficationConnectionMessage
      );
    });
  }

  @action
  onNotficationConnectionClose() {
    // close event is also fired when Websocket is closed by calling `close` method on it
    // but we only close Websocket with intent when it is unauthenticated
    if (this.session.isAuthenticated) {
      this.retryAfterDelay.perform();
    }
  }

  @action
  onNotficationConnectionMessage(e) {
    const livePdcNotificationPayload = JSON.parse(e.data);
    const modelClass = this.store.modelFor('pdc-notification');
    const serializer = this.store.serializerFor('pdc-notification');
    const livePdcNotificationNormalizedPayload = serializer.normalize(
      modelClass,
      livePdcNotificationPayload.data
    );
    const livePdcNotification = this.store.push(
      livePdcNotificationNormalizedPayload
    );
    if (isEmpty(livePdcNotification)) {
      throw 'PDC Notification message is empty.';
    }
    const isNewLivePdcNotification =
      !this.pdcNotifications.includes(livePdcNotification);
    if (isNewLivePdcNotification) {
      this.pdcNotifications = [livePdcNotification, ...this.pdcNotifications];
    }
  }
}
