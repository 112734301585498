import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{did-update this.onAuthenticationChange this.session.isAuthenticated}}", {"contents":"{{did-update this.onAuthenticationChange this.session.isAuthenticated}}","moduleName":"client-app-omnivise-web/components/redirect-to-login-if-unauthenticated.hbs","parseOptions":{"srcName":"client-app-omnivise-web/components/redirect-to-login-if-unauthenticated.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 * This component observes the `isAuthenticated` state of session service. If
 * it changes to `false`, the component triggers a transition to `login` route.
 *
 * Changes to `isAuthenticated` state are observed using `{{did-update}}`
 * helper from `ember-render-helpers`.
 *
 * Conceptually this is an observer pattern. But observers aren't part of
 * Ember's programming modal anymore since Octane. So kind of misuing a
 * component to have an observer style pattern.
 *
 * Ember is currently missing a good pattern for this kind of situations.
 * There as been a proposal to add `@use` and `Resources` for this use case.
 * But it's still an experiment. We might want to refactor to that one when
 * it's more stable. Please find more information about it in this RFC
 * https://github.com/emberjs/rfcs/pull/567 and this blog post
 * https://www.pzuraq.com/introducing-use/.
 */
export default class RedirectToLoginIfUnauthenticated extends Component {
  @service session;
  @service router;

  @action
  async onAuthenticationChange() {
    if (!this.session.isAuthenticated) {
      this.session.stopListeningForLogoutEvents();

      this.session.redirectUri = window.location.pathname;
      await this.router.transitionTo('login').promise;
    }
  }
}
