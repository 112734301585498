import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class ViewRoute extends Route {
  @service store;
  @service router;

  async model(parameters) {
    try {
      return {
        internalView: await this.store.findRecord(
          'internal-view',
          parameters.view_id,
          {
            include: [
              'module',
              'configuredWidgets.configuredComponents.componentDescriptor',
            ].join(','),
          }
        ),
      };
    } catch (e) {
      if (e?.errors?.firstObject?.code === 403) {
        this.router.transitionTo('auth');
      }
      throw e;
    }
  }
}
