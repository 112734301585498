import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthRoute extends Route {
  @service router;
  @service session;

  async beforeModel(transition) {
    if (!this.session.isAuthenticated) {
      this.session.redirectUri = transition.intent.url;
      return this.router.transitionTo('login');
    }
  }
}
